@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@200;300;400;600;700&display=swap');

:root {
    --primary-degree: linear-gradient(90deg, #E3FD39, #F722A3, #A09CFF);
    --secundary-degree: linear-gradient(120deg, #E3FD39, #F722A3, #A09CFF);
    --color-1: #E3FD39;
    --color-2: #F722A3;
    --color-3: #A09CFF;
    --color-light: #FAFAFA;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Blinker', sans-serif;
}

.font-grotesk {
    font-family: 'Clash Grotesk', sans-serif;
}

.back-light {
    background: var(--color-light);
}

.back-light .active {
    background: var(--color-light);
    color: #FFFFFF;
}

.border-main {
    background: linear-gradient(white, white) padding-box,
        var(--secundary-degree) border-box;
    border: 2px solid transparent;
}

.rounded-border-main {
    background: linear-gradient(white, white) padding-box,
        var(--primary-degree) border-box;
    border-radius: 50em;
    border: 4px solid transparent;
}


.main-background {
    background: var(--primary-degree);
}

.menu-list .nav-link.active {
    background: var(--secundary-degree);
    border: white 1px solid;
    color: #FFFFFF;
    text-align: center;
}

.btn-main {
    background: var(--primary-degree);
    border: white 1px solid;
    color: #FFFFFF;

    text-align: center;
    width: 100%;
    border-radius: 16px;
    padding: 10px 24px;

    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.11999999731779099px;
}

.btn-secundary {
    background: var(--primary-degree);
    border: white 1px solid;
    color: #FFFFFF;

    text-align: center;
    width: 100%;
    border-radius: 16px;
    padding: 10px 5px;

    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.11999999731779099px;
}

.border-primary {
    background: linear-gradient(white, white) padding-box,
        var(--secundary-degree) border-box;
    border: 2px solid transparent;
    color: #000000;

    text-align: center;
    width: 100%;
    border-radius: 16px;
    padding: 10px 5px;

    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.11999999731779099px;
}

.btn-main:hover {
    border: white 1px solid;
    color: #FFFFFF;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(0, 1fr));
    gap: 2rem;
}

.table-width-columns {
    table-layout: fixed;
}